import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Button = (props) => {
  const buttonContentRendered = (
    <ContentDiv disabled={props.disabled}>{props.children}</ContentDiv>
  )

  if (props.to) {
    return (
      <LinkStyled className={props.className} to={props.to}>
        {buttonContentRendered}
      </LinkStyled>
    )
  }

  return (
    <ButtonStyled
      className={props.className}
      disabled={props.disabled}
      onClick={props.handleClick}
      type={props.type}
    >
      {buttonContentRendered}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button`
  border: 0;
  border-radius: 5px;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`

const ContentDiv = styled.div`
  background-color: white;
  box-shadow: inset 0 0 0 transparent;
  color: black;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  display: inline-block;
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSizeMedium}px;
  font-weight: bold;
  line-height: normal;
  padding: ${(props) => props.theme.fontSizeMedium}px
    ${(props) => props.theme.fontSizeMedium * 2}px;
  transition: all ${(props) => props.theme.transitionDurationShort}s;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`

const LinkStyled = styled(Link)`
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
`

Button.propTypes = {
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  disabled: false,
}

export default Button
