import React, {memo, useCallback, useState} from "react"
import Carousel, {Modal, ModalGateway} from "react-images"
import ReactPhotoGallery from "react-photo-gallery"
import styled from "styled-components"
import Boundary from "../components/Boundary"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Spacer from "../components/Spacer"
import photoGalleryJSON from "../data/photo-gallery.json"

const PhotoGallery = memo(() => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, {/*photo, */ index}) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <OuterDiv>
      <Gutter isDesktop={false}>
        <Gutter leftPadding={false} rightPadding={false}>
          <Boundary>
            <Spacer isDesktop={false} />
            <Heading>Recent memories</Heading>
            <p>
              From Qur'an contests to Thanksgiving feasts and Iftar dinners, our
              Assalam Center family has been fortunate to host countless
              memories for our students and their parents. We hope to see you
              and your family at some of our future events!
            </p>
            <ReactPhotoGallery
              photos={photoGalleryJSON}
              onClick={openLightbox}
            />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photoGalleryJSON.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </Boundary>
        </Gutter>
      </Gutter>
    </OuterDiv>
  )
})

const OuterDiv = styled.div`
  background-color: black;
  color: white;
  img {
    border-radius: 15px;
  }
`

export default PhotoGallery
