import "firebase/firestore"
import React, {memo} from "react"
import styled from "styled-components"
import Flex from "../components/Flex"
import cashLogoPNG from "../images/cash-logo.svg"
import zelleLogoPNG from "../images/zelle-logo.png"
import phoneSVG from "../images/phone.svg"
import {device} from "../helpers/mediaQueries"
import DonateLink from "./DonateLink"

const Donate = memo(() => {
  return (
    <Flex isMobile={false} justifyContent="space-between">
      <DonateItemDiv>
        <DonateLink
          imageAlt="Phone"
          imageSrc={phoneSVG}
          label="Phone"
          link="tel:+15613918285"
          value="(561) 391-8285"
        />
      </DonateItemDiv>
      <DonateItemDiv>
        <DonateLink
          color="#0abe49"
          imageAlt="Cash App logo"
          imageSrc={cashLogoPNG}
          isRoundedLogo
          label="Cash App"
          link="https://cash.app/assalamcenter/20"
          value="$AssalamCenter"
        />
      </DonateItemDiv>
      <DonateItemDiv>
        <DonateLink
          color="#6d1ed4"
          imageAlt="Zelle logo"
          imageSrc={zelleLogoPNG}
          isRoundedLogo
          label="Zelle"
          value="AssalamCenter96@gmail.com"
        />
      </DonateItemDiv>
    </Flex>
  )
})

const DonateItemDiv = styled.div`
  margin-bottom: 20px;
  @media ${device.desktop} {
    margin-bottom: 0;
  }
`

export default Donate
