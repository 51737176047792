import "firebase/firestore"
import React from "react"
import Page from "../components/Page"
import Fundraiser from "../widgets/Fundraiser"
import Hero from "../widgets/Hero"
import Location from "../widgets/Location"
import PhotoGallery from "../widgets/PhotoGallery"
import PrayerTimes from "../widgets/PrayerTimes"

const IndexPage = () => {
  return (
    <Page disableHeadroom>
      <Fundraiser />
      <Hero />
      <PrayerTimes />
      <PhotoGallery />
      <Location />
    </Page>
  )
}

export default IndexPage
