import React, {memo} from "react"
import Boundary from "../components/Boundary"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Spacer from "../components/Spacer"
import Donate from "./Donate"
import LocationMap from "./LocationMap"

const Location = memo(() => {
  return (
    <Gutter isDesktop={false}>
      <Gutter leftPadding={false} rightPadding={false}>
        <Spacer isDesktop={false} />
        <Boundary>
          <Heading>Come visit us!</Heading>
          <p>
            If you're coming from Glades Road, turn north onto 4th Avenue and
            you will see our beautiful dome on the left. If you're coming from
            Yamato Road, head south on 4th Avenue until you approach Glades
            Road, and you will see us on the right. See you soon!
          </p>
          <LocationMap />
          <br />
          <br />
          <Donate />
        </Boundary>
      </Gutter>
    </Gutter>
  )
})

export default Location
