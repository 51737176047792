import React from "react"
import styled from "styled-components"
import Boundary from "../components/Boundary"
// import Flex from "../components/Flex"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Spacer from "../components/Spacer"

const Fundraiser = () => {
  return (
    <OuterDiv>
      <Gutter isDesktop={false} leftPadding={false} rightPadding={false}>
        <Gutter leftPadding={false} rightPadding={false}>
          <Spacer isDesktop={false} />
          <Boundary>
            <Heading>🥘 Assalam Center Annual Fundraiser</Heading>
            <p>
              You and your family are warmly invited to join us for our annual
              fundraising dinner. We rely heavily on our community's
              contributions to keep our school's future bright إن شاء الله
            </p>
            <p>
              <strong>April 8, 2023 (after Maghrib prayer)</strong>
              <br />
              <LinkA href="https://www.google.com/maps/place/Assalam+Center/@26.3645436,-80.0941287,15z/data=!4m2!3m1!1s0x0:0xb36eecf32b4bc911?sa=X&ved=2ahUKEwjpypvqxKzzAhUEHTQIHc40D4AQ_BJ6BAhSEAU">
                <small>1499 NW 4th Ave, Boca Raton, FL 33432</small>
              </LinkA>
            </p>
          </Boundary>
        </Gutter>
      </Gutter>
    </OuterDiv>
  )
}

const OuterDiv = styled.div``

const LinkA = styled.a`
  color: #0078c6;
`

export default Fundraiser
