/* eslint-disable react/jsx-no-target-blank */
import React, {memo} from "react"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"
import mapPNG from "../images/map.png"

const LocationMap = memo(() => {
  return (
    <OuterAnchor
      href="https://www.google.com/maps/place/Assalam+Center/@26.3645436,-80.0941287,15z/data=!4m2!3m1!1s0x0:0xb36eecf32b4bc911?sa=X&ved=2ahUKEwjpypvqxKzzAhUEHTQIHc40D4AQ_BJ6BAhSEAU"
      target="_blank"
    >
      <OuterDiv>
        <AddressDiv>1499 NW 4th Ave, Boca Raton, FL 33432</AddressDiv>
      </OuterDiv>
    </OuterAnchor>
  )
})

const AddressDiv = styled.div`
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
  bottom: 0;
  color: black;
  font-size: ${(props) => props.theme.fontSizeSmall}px;
  left: 0;
  padding: 100px 0 10px;
  position: absolute;
  right: 0;
`

const OuterAnchor = styled.a`
  display: block;
`

const OuterDiv = styled.div`
  background-image: url(${mapPNG});
  background-position: 60% 30%;
  background-size: cover;
  border: 1px solid black;
  border-radius: 15px;
  height: 350px;
  overflow: hidden;
  position: relative;
  @media ${device.desktop} {
    background-position: center 0%;
  }
`

export default LocationMap
