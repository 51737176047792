import moment from "moment"
import React, {useEffect, useState} from "react"
import styled from "styled-components"
import Boundary from "../components/Boundary"
import Flex from "../components/Flex"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Spacer from "../components/Spacer"
import asrJPEG from "../images/prayerTimes/asr.jpeg"
import dhuhrJPEG from "../images/prayerTimes/dhuhr.jpeg"
import fajrJPEG from "../images/prayerTimes/fajr.jpeg"
import ishaJPEG from "../images/prayerTimes/isha.jpeg"
import maghribJPEG from "../images/prayerTimes/maghrib.jpeg"
import {device} from "../helpers/mediaQueries"

const PrayerTimes = () => {
  // Prayer times
  const [prayerTimes, setPrayerTimes] = useState({})

  useEffect(() => {
    fetch(
      "https://api.aladhan.com/v1/calendarByCity?city=miami&state=fl&country=us"
    )
      .then((response) => response.json())
      .then((json) => {
        const {data} = json

        if (data && data.length > 0) {
          const dayOfMonth = moment().format("D")

          const prayerTimes = data[dayOfMonth - 1]

          const {
            Asr,
            Dhuhr,
            Fajr,
            // Imsak,
            Isha,
            Maghrib,
            // Midnight,
            Sunrise,
            Sunset,
          } = prayerTimes.timings

          setPrayerTimes({
            asr: formatTime(Asr),
            dhuhr: formatTime(Dhuhr),
            fajr: formatTime(Fajr),
            isha: formatTime(Isha),
            maghrib: formatTime(Maghrib),
            sunrise: formatTime(Sunrise),
            sunset: formatTime(Sunset),
          })
        }
        /*if (code === 200) {
          const {datetime} = results
          const {/!*date, *!/ times} = datetime[0]
          // const {gregorian} = date
          const {
            Asr,
            Dhuhr,
            Fajr,
            // Imsak,
            Isha,
            Maghrib,
            // Midnight,
            Sunrise,
            Sunset,
          } = times


        }*/
      })
  }, [])
  return (
    <OuterDiv>
      <Gutter isDesktop={false} leftPadding={false} rightPadding={false}>
        <Gutter leftPadding={false} rightPadding={false}>
          <Spacer isDesktop={false} />
          <Boundary>
            <Heading>Prayer times</Heading>
            <br />
            <ItemsBoundaryDiv>
              <Flex isMobile={false}>
                <ItemDiv>
                  <ItemIconDiv>
                    <img alt="Fajr graphic" src={fajrJPEG} />
                  </ItemIconDiv>
                  <ItemDetailsDiv>
                    <div>Fajr</div>
                    <ItemTimeDiv>{prayerTimes.fajr}</ItemTimeDiv>
                  </ItemDetailsDiv>
                </ItemDiv>
                <ItemDiv>
                  <ItemIconDiv>
                    <img alt="Dhuhr graphic" src={dhuhrJPEG} />
                  </ItemIconDiv>
                  <ItemDetailsDiv>
                    <div>Dhuhr</div>
                    <ItemTimeDiv>{prayerTimes.dhuhr}</ItemTimeDiv>
                  </ItemDetailsDiv>
                </ItemDiv>
                <ItemDiv>
                  <ItemIconDiv>
                    <img alt="Asr graphic" src={asrJPEG} />
                  </ItemIconDiv>
                  <ItemDetailsDiv>
                    <div>Asr</div>
                    <ItemTimeDiv>{prayerTimes.asr}</ItemTimeDiv>
                  </ItemDetailsDiv>
                </ItemDiv>
                <ItemDiv>
                  <ItemIconDiv>
                    <img alt="Maghrib graphic" src={maghribJPEG} />
                  </ItemIconDiv>
                  <ItemDetailsDiv>
                    <div>Maghrib</div>
                    <ItemTimeDiv>{prayerTimes.maghrib}</ItemTimeDiv>
                  </ItemDetailsDiv>
                </ItemDiv>
                <ItemDiv>
                  <ItemIconDiv>
                    <img alt="Isha graphic" src={ishaJPEG} />
                  </ItemIconDiv>
                  <ItemDetailsDiv>
                    <div>Isha</div>
                    <ItemTimeDiv>{prayerTimes.isha}</ItemTimeDiv>
                  </ItemDetailsDiv>
                </ItemDiv>
              </Flex>
            </ItemsBoundaryDiv>
          </Boundary>
        </Gutter>
      </Gutter>
    </OuterDiv>
  )
}

const formatTime = (timeWithZone) => {
  const time = timeWithZone.split(" ")

  return moment(time, "HH:mm").format("h:mma")
}

const ItemDiv = styled.div`
  line-height: 1.4;
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
  @media ${device.desktop} {
    width: 20%;
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`

const ItemDetailsDiv = styled.div`
  margin-top: 5px;
`

const ItemIconDiv = styled.div`
  img {
    border-radius: 40px;
    height: 80px;
  }
`

const ItemTimeDiv = styled.div`
  font-weight: bold;
`

const ItemsBoundaryDiv = styled.div`
  @media ${device.desktop} {
    margin-left: -7%;
    width: 114%;
  }
`

const OuterDiv = styled.div``

export default PrayerTimes
