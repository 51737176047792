import "firebase/firestore"
import PropTypes from "prop-types"
import React, {memo} from "react"
import styled from "styled-components"
import Flex from "../components/Flex"
import {device} from "../helpers/mediaQueries"

const DonateLink = memo(
  ({color, imageAlt, imageSrc, isRoundedLogo, label, link, value}) => {
    const content = (
      <OuterFlex color={color} isRoundedLogo={isRoundedLogo}>
        <img src={imageSrc} alt={imageAlt} />
        <div>
          <div>{label}</div>
          <ValueDiv>{value}</ValueDiv>
        </div>
      </OuterFlex>
    )

    if (!link) {
      return content
    }

    return <AnchorStyled href={link}>{content}</AnchorStyled>
  }
)

const AnchorStyled = styled.a`
  display: block;
`

const OuterFlex = styled(Flex)`
  border: 1px solid ${(props) => props.color};
  border-radius: 40px;
  color: ${(props) => props.color};
  font-size: ${(props) => props.theme.fontSizeSmall}px;
  height: ${(props) => props.theme.fontSizeSmall * 4.5}px;
  line-height: 1.25;
  padding-left: ${(props) => (props.isRoundedLogo ? 20 : 25)}px;
  text-align: left;
  img {
    border-radius: ${(props) => props.isRoundedLogo && "50%"};
    height: ${(props) =>
      props.isRoundedLogo
        ? props.theme.fontSizeSmall * 2
        : props.theme.fontSizeSmall * 1.5}px;
    margin-right: 15px;
  }
  @media ${device.desktop} {
    height: 80px;
    padding-left: ${(props) => (props.isRoundedLogo ? 20 : 30)}px;
    padding-right: 40px;
    img {
      height: ${(props) =>
        props.isRoundedLogo
          ? props.theme.fontSizeMedium * 2
          : props.theme.fontSizeMedium * 1.5}px;
    }
  }
`

const ValueDiv = styled.div`
  font-weight: 600;
`

DonateLink.propTypes = {
  color: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  isRoundedLogo: PropTypes.bool,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  value: PropTypes.string.isRequired,
}

DonateLink.defaultProps = {
  color: "black",
  isRoundedLogo: false,
  link: "",
}

export default DonateLink
