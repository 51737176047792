import React, {memo} from "react"
import {Tween} from "react-gsap"
import {Controller, Scene} from "react-scrollmagic"
import styled from "styled-components"
import Button from "../components/Button"
import {Heading} from "../components/Heading"
import {device} from "../helpers/mediaQueries"
import mosqueJPEG from "../images/mosque.jpeg"
import Gutter from "../components/Gutter"
import Spacer from "../components/Spacer"

const scrollMagicHeight = 200

const Hero = memo(() => {
  return (
    <OuterDiv>
      <Controller>
        <Scene duration="100%">
          <Tween
            ease="linear"
            from={{transform: "translateY(0)"}}
            to={{transform: `translateY(${scrollMagicHeight * 2}px)`}}
          >
            <ImageOuterDiv>
              <ImageInnerDiv src={mosqueJPEG} />
            </ImageOuterDiv>
          </Tween>
        </Scene>
      </Controller>
      <CallToActionDiv>
        <Spacer isDesktop={false} />
        <Spacer isDesktop={false} />
        <Gutter isDesktop={false}>
          <Heading type="1">Ramadan Kareem ❤️</Heading>
          <p>
            Assalam Center is a hub for the diverse Muslim community in South
            Florida. We are also a school that offers Arabic and Islamic studies
            for children and adults. We welcome you and your family to pray and
            share iftar with us during Ramadan.
          </p>
          <ButtonStyled
            backgroundColor="white"
            backgroundColorHover="white"
            to="/about"
          >
            Get to know us
          </ButtonStyled>
          <Spacer isDesktop={false} />
          <Spacer isDesktop={false} />
          <Spacer isDesktop={false} />
        </Gutter>
      </CallToActionDiv>
    </OuterDiv>
  )
})

const ButtonStyled = styled(Button)`
  background-color: white;
  transition: box-shadow ${(props) => props.theme.transitionDurationShort}s;
  &:hover {
    box-shadow: 0 0 100px rgba(243, 255, 131, 0.75);
  }
`

const CallToActionDiv = styled.div`
  color: white;
  position: relative;
  @media ${device.desktop} {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
  }
`

const ImageInnerDiv = styled.div`
  background: url(${(props) => props.src}) top no-repeat;
  background-size: cover;
  bottom: 0;
  height: calc(100% + ${scrollMagicHeight}px);
  left: 0;
  opacity: 0.55;
  position: absolute;
  right: 0;
`

const ImageOuterDiv = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
`

const OuterDiv = styled.div`
  background-color: #111;
  overflow-y: hidden;
  position: relative;
  @media ${device.desktop} {
    height: calc(95vh - ${(props) => props.theme.navigationDesktopHeight}px);
  }
`

export default Hero
